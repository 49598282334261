import React from 'react'
import aboutUsImage from '../../assets/images/purple.png'

const AboutUs = () => {
  return (
    <div className='mx-auto md:px-32 px-5 mt-14'>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-20 gap-y-5'>
        <div className='flex justify-center flex-col'>
          <div className=''>
            <h3 className='text-left font-campton-light text-4xl font-thin text-gray-700'>
              About us
            </h3>
            <div className='w-20 h-1.5 mt-3 bg-primary-pink ' />
          </div>
          <p className='font-bold font-campton-light  text-gray-900 my-3'>
            We are shareholders and well vested in the growth of your business.Our research team
            reaches out to you with new innovative print options.
          </p>
          <p className='mb-3 font-campton-light text-gray-700'>
            with our network of highly skilled professionals makes it seemless to achieve excellent
            results
          </p>
          <p className='mb-3 font-campton-light text-gray-700'>
            Our speciliazed team of creatives well understand the clients process which results in
            end-to-end delivery. We are also able to work with over 90% design softwares.
          </p>
          <p className='mb-3 font-campton-light text-gray-700'>
            Communication is key, we maintain a strong line of communication, before, during and
            after every project.
          </p>
        </div>
        <div className='relative'>
          <div className='h-full'>
            <img src={aboutUsImage} className='h-full w-full object-cover' />
          </div>
          <div className='absolute inset-0 grid place-content-center text-center'>
            <a href='#portfolio' className='text-white text-xs bg-primary-purple px-5 py-4'>
              SEE OUR WORK
              <i className='arrow right ml-16' />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
