import React from 'react'
import one from '../../assets/images/one.png'
import two from '../../assets/images/two.png'
import five from '../../assets/images/five.png'
import twenty from '../../assets/images/twenty.png'
import hundred from '../../assets/images/hundred.png'
import Twohundred from '../../assets/images/Twohundred.png'

const Number = () => {
  return (
    <div className='mx-auto px-5 md:px-32 mt-16'>
      <h3 className='text-3xl font-Campton'>Numbers Don’t Lie</h3>
      <div style={{ backgroundColor: '#B8519D' }} className='w-20 h-1.5 mt-3' />
      <div className='grid xl:grid-cols-5 md:grid-cols-3 grid-cols-1 sm:grid-cols-2 mt-10 gap-5'>
        <div className='  bg-white p-3 '>
          <div className='flex justify-center'>
            <img src={hundred} />
          </div>
          <h1 className='font-bold text-sm py-4 text-center font-Campton'>Business Essentials</h1>
          <p className='text-center text-sm font-Campton'>
            Business cards, letter head papers, envelopes and more are produced for companies,
            government parastatals and individuals.
          </p>
        </div>
        <div className=' bg-white p-3 '>
          <div className='flex justify-center'>
            <img src={Twohundred} />
          </div>
          <h1 className='font-bold text-sm py-4 text-center font-Campton'>Marketing Essentials</h1>
          <p className='text-center text-sm font-Campton'>
            {' '}
            Produced annually for our corporate, government and individual clients.
          </p>
        </div>
        <div className='hidden md:block bg-white p-3 '>
          <div className='flex justify-center'>
            <img src={two} />
          </div>
          <h1 className='font-bold text-sm py-4 text-center font-Campton'>Large Format Display</h1>
          <p className='text-center text-sm font-Campton'>
            {' '}
            Are procured for brands, business executives and corporate institutions.
          </p>
        </div>
        <div className='hidden xl:block bg-white p-3  '>
          <div className='flex justify-center'>
            <img src={five} />
          </div>
          <h1 className='font-bold text-sm py-4 text-center font-Campton'>
            {' '}
            Indoor & Outdoor Signage{' '}
          </h1>
          <p className='text-center text-sm font-Campton'>
            Designed and produced for businesses and government establishments.
          </p>
        </div>
        <div className='hidden xl:block  bg-white p-3 '>
          <div className='flex justify-center'>
            <img src={hundred} />
          </div>
          <h1 className='font-bold text-sm py-4 text-center font-Campton'>
            Gift Items and Writing Materials
          </h1>
          <p className='text-center text-sm font-Campton'>
            {' '}
            Produced for government leaders, business executives and individuals.
          </p>
        </div>
      </div>
      <div className='grid xl:grid-cols-4 md:grid-cols-3 grid-cols-1 sm:grid-cols-2 xl:w-4/5 mx-auto gap-5'>
        <div className='xl:hidden  bg-white p-3  '>
          <div className='flex justify-center'>
            <img src={five} />
          </div>
          <h1 className='font-bold text-sm py-4 text-center font-Campton'>
            Indoor & Outdoor Signage{' '}
          </h1>
          <p className='text-center text-sm font-Campton'>
            Designed and produced for businesses and government establishments.
          </p>
        </div>
        <div className='xl:hidden  bg-white p-3 '>
          <div className='flex justify-center'>
            <img src={hundred} />
          </div>
          <h1 className='font-bold text-sm py-4 text-center font-Campton'>
            Gift Items and Writing Materials
          </h1>
          <p className='text-center text-sm font-Campton'>
            {' '}
            Produced for government leaders, business executives and individuals.
          </p>
        </div>
        <div className='bg-white p-3 '>
          <div className='flex justify-center'>
            <img src={one} />
          </div>
          <h1 className='font-bold text-sm py-4 text-center font-Campton'>Carriage Items</h1>
          <p className='text-center text-sm font-Campton'>
            {' '}
            Are procured for brands, business executives and individuals.
          </p>
        </div>
        <div className='   bg-white p-3 '>
          <div className='flex justify-center'>
            <img src={twenty} />
          </div>
          <h1 className='font-bold text-sm py-4 text-center font-Campton'>Drink Wares</h1>
          <p className='text-center text-sm font-Campton'>
            {' '}
            Designed and produced for individuals, businesses and government establishments.
          </p>
        </div>
        <div className='  bg-white p-3 '>
          <div className='flex justify-center'>
            <img src={five} />
          </div>
          <h1 className='font-bold text-sm py-4 text-center font-Campton'>On Display</h1>
          <p className='text-center text-sm font-Campton'>
            {' '}
            Produced annually for our corporate and government clients..
          </p>
        </div>
        <div className='   bg-white p-3 '>
          <div className='flex justify-center'>
            <img src={Twohundred} />
          </div>
          <h1 className='font-bold text-sm py-4 text-center font-Campton'>Digigifts</h1>
          <p className='text-center text-sm font-Campton'>
            {' '}
            Individuals, brands and government parastatals use our services to design and produce
            annually.
          </p>
        </div>
        <div className=' md:hidden bg-white p-3 '>
          <div className='flex justify-center'>
            <img src={two} />
          </div>
          <h1 className='font-bold text-sm py-4 text-center font-Campton'>Large Format Display</h1>
          <p className='text-center text-sm font-Campton'>
            {' '}
            Are procured for brands, business executives and corporate institutions.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Number
