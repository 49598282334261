import coletteProfile from '../assets/images/colette.png'
import kaodoProfile from '../assets/images/kaodo.png'

export const testimonies = [
  {
    name: 'Colette Amaeshi',
    email: 'coletteamaeshi@ultimusholdings.com',
    company: 'Ultimus Holdings',
    positions: 'Marketing & Communications Manager',
    project: '3 X-banners ',
    whatYouThink: 'Swift, this proves your team is reliable',
    image: coletteProfile
  },
  {
    name: 'Kooda Theophilus Terkuma ',
    email: 'theo@hirefreehands.tech',
    company: 'Hirefreehands',
    positions: 'Director of Operations - Nigeria ',
    project: 'Swag package customization ',
    whatYouThink:
      'ETH Global for me did an exceedingly outstanding job.  They delivered the products ahead of schedule even when our demands were made within a tight time frame.  Excellent services I must confess.   ',
    image: kaodoProfile
  },
  {
    name: 'Debola ',
    email: 'aadefarati@teamapt.com',
    company: 'TeamApt',
    positions: 'Marketing Manager ',
    project: 'Point of Sale Branding Materials ',
    whatYouThink:
      'I love the dedication ETH Global gives to every job. They are very professional, pay attention to detail and advise you on pitfalls when need be. For context, in the early days of the lockdown when there was a no movement order, ETH still found a way to deliver the materials we needed.'
  },
  {
    name: 'Andre ',
    email: 'andre.adekoya@redbull.com',
    company: 'Red Bull',
    positions: 'Specialist ',
    project: "Red Bull Neymar Jr's Five & Half Court ",
    whatYouThink: 'Satisfactory.',
    image: ''
  }
]
