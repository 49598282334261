/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import Rating from '../../assets/images/Rating.png'
import Quality from '../../assets/images/Quality.png'
import Group from '../../assets/images/Group 19.png'
import Flexible from '../../assets/images/Flexible.png'
import Bulb from '../../assets/images/Bulb.png'
import Communication from '../../assets/images/Communication.png'
import scrollIcon from '../../assets/images/cooliconv.png'

const WorkWithUs = () => {
  const scrollRef = useRef()
  const [next, scrollNext] = useState(500)

  const handleScrollEvent = () => {
    if (scrollRef.current !== null) {
      if (window.pageYOffset < 3800) {
        scrollRef.current.style.display = 'block'
      } else {
        scrollNext(500)
        scrollRef.current.style.display = 'none'
      }
    }
  }

  const scrollToDown = () => {
    window.scrollTo({
      top: next,
      behavior: 'smooth'
    })
    const valueNext = window.screen.width > 800 ? next + 500 : next + 400
    scrollNext(valueNext)
  }

  useEffect(() => {
    document.addEventListener('scroll', handleScrollEvent)

    return () => document.removeEventListener('scroll', handleScrollEvent)
  })

  return (
    <>
      <div
        onClick={scrollToDown}
        ref={scrollRef}
        className='w-32 h-60 fixed top-3/4 hidden md:block animate-animate-bounce-2s'
      >
        <p className='absolute'>
          <p className='transform rotate-90'>Scroll, please.</p>
          <img className='mt-14 ml-10' src={scrollIcon} alt='scroll' />
        </p>
      </div>
      <div className=' bg-primary-pink px-5 md:px-32 py-16 mt-10'>
        <h3 className='text-3xl text-white font-campton-light'>Why Work with Us</h3>
        <div className='bg-white w-20 h-1.5 mt-3' />
        <div className='py-5 grid xl:grid-cols-6 md:grid-cols-3 grid-cols-1 sm:grid-cols-2  gap-6 container mx-auto px-0'>
          <div className='grid place-content-center px-4 bg-white py-7 shadow-primary'>
            <div className='mb-5 flex justify-center '>
              <img src={Group} />
            </div>
            <p className=' font-campton-light  font-light text-center '>Competitive Costs</p>
          </div>
          <div className='grid place-content-center px-4 bg-white py-7 shadow-primary'>
            <div className='mb-5 flex justify-center '>
              <img src={Flexible} />
            </div>
            <p className='font-campton-light  font-light text-center mt-3'>
              Flexible functionality and deployment.
            </p>
          </div>
          <div className='grid place-content-center px-4 bg-white py-7 shadow-primary'>
            <div className='mb-7 flex justify-center'>
              <img src={Communication} />
            </div>
            <p className='font-campton-light font-normal text-center '>Great Communication</p>
          </div>
          <div className='grid place-content-center px-4 bg-white py-7 shadow-primary'>
            <div className='mb-5 flex justify-center'>
              <img src={Quality} />
            </div>
            <p className='font-campton-light font-normal text-center'>
              Quality and On-time delivery
            </p>
          </div>
          <div className='grid place-content-center px-4 bg-white py-7 shadow-primary '>
            <div className='mb-7 flex justify-center'>
              <img src={Rating} />
            </div>
            <p className='font-campton-light font-normal text-center'>Client Satisfaction</p>
          </div>
          <div className='grid place-content-center px-4 bg-white py-5 shadow-primary '>
            <div className='mb-5 flex justify-center'>
              <img src={Bulb} />
            </div>
            <p className=' font-normal text-center'>
              Great Briefs <span>&#47;</span> Bids Understanding
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default WorkWithUs
