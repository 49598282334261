import React from 'react'
import Ourclients from './ourClients'
import Ourportfolio from './Ourportfolio'

import WorkWithUs from './WorkWithUs'
import Number from './Number'
import Form from './Form'
import Footer from './Footer'
import Header from './Header'
import AboutUs from './AboutUs'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectFade, Navigation, Autoplay } from 'swiper/core'
import 'swiper/swiper.min.css'
import 'swiper/components/effect-fade/effect-fade.min.css'
import 'swiper/components/navigation/navigation.min.css'

SwiperCore.use([EffectFade, Navigation, Autoplay])

const Landing = () => {
  return (
    <div>
      <Header />
      <div>
        <AboutUs />
        <WorkWithUs />
        <Number />
        <div>
          <div>
            <div className='bg-gray-100 mt-5 py-10 pb-20'>
              <div className='mx-auto'>
                <p className='text-center text-xl font-campton-light'>Clients</p>
                <div className='w-14 h-1.5 mt-3 bg-primary-pink mx-auto' />
              </div>
              <div className='container mx-auto mt-14'>
                <Swiper
                  autoplay
                  loop
                  spaceBetween={5}
                  slidesPerView={4}
                  className='flex items-center'
                >
                  <SwiperSlide>
                    <div className=''>
                      <img src='/clients/1q.png' alt='accelerate' />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className=''>
                      <img src='/clients/2q.png' alt='access' />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className=''>
                      <img src='/clients/3q.png' alt='addax' />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className=''>
                      <img src='/clients/4q.png' alt='ana-govt' />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className=''>
                      <img src='/clients/5q.png' alt='ariosh' />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className=''>
                      <img className='mt-5' src='/clients/6q.png' alt='arney' />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img className='mt-5' src='/clients/7q.png' alt='chatered' />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img className='mt-5' src='/clients/8q.png' alt='clarion' />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img className='mt-5' src='/clients/9q.png' alt='dmg-events' />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img className='md:mt-1 mt-5' src='/clients/10q.png' alt='emwa' />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className=''>
                      <img className='mt-5' src='/clients/11q.png' alt='geeksona' />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className=''>
                      <img className='mt-5' src='/clients/12q.png' alt='gov' />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className=''>
                      <img className='mt-5' src='/clients/13q.png' alt='gtbank' />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className=''>
                      <img className='mt-5' src='/clients/14q.png' alt='hire-freehands' />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className=''>
                      <img className='mt-5' src='/clients/15q.png' alt='honey-well' />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Ourportfolio />
      <Ourclients />
      <Form />
      <Footer />
    </div>
  )
}

export default Landing
