export const INPUTS_FIELDS = [
  {
    placeholder: 'Enter your name',
    label: 'Your name',
    name: 'name',
    type: 'text',
    required: true
  },
  {
    placeholder: 'Enter your work email',
    label: 'Email address',
    name: 'email',
    type: 'email',
    required: true
  },
  {
    placeholder: 'Enter your company’s name',
    label: 'Your company',
    name: 'companyname',
    type: 'text',
    required: true
  },
  {
    placeholder: 'E.g. Hello, I’d like to...',
    label: 'Share your brief',
    name: 'aboutyourcompany',
    type: 'text'
  }
]
