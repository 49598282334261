import React from 'react'
import Navbar from '../navbar/Navbar'
import star from '../../assets/images/Ethglobalinc.png'
const Header = () => {
  return (
    <div className='relative'>
      <Navbar />
      <div
        className='grid-cols-2 md:grid flex flex-col justify-center'
        style={{ minHeight: '80vh' }}
      >
        <div className='flex flex-col justify-center items-center px-5'>
          <h1 className='text-primary-pink font-campton-light text-6xl font-semibold max-w-lg mt-20'>
            Take Your Brand to the Stars
          </h1>
          <p className='max-w-lg text-gray-700 font-thin font-campton-light text-sm mt-5'>
            We have delivered exceptional value with attention to details on designing and producing
            marketing carboard over the years.
          </p>
        </div>
        <div className='hidden md:flex'>
          <img src={star} />
        </div>
      </div>
    </div>
  )
}

export default Header
