import React, { useState } from 'react'
import { INPUTS_FIELDS } from '../constant'
import { BASE_URL } from '../../utils/config'
import Contact from '../../assets/images/Contact.png'
import arrow from '../../assets/images/coolicon.png'
import axios from 'axios'

const Form = () => {
  const [userInput, setUserInput] = useState({})
  const [error, seterror] = useState('')
  const [loader, setlaoder] = useState(false)
  const [message, setmessage] = useState('')
  const handleInputChange = event => {
    setUserInput({ ...userInput, [event.target.name]: event.target.value })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    setlaoder(true)

    const data = JSON.stringify(userInput)
    const headers = { 'Content-Type': 'application/json' }
    try {
      const response = await axios.post(`${BASE_URL}/register-success`, data, { headers: headers })

      setlaoder(false)
      // console.log(errors)
      setmessage(response.data?.message || 'Successfully Registered')
    } catch (error) {
      seterror(error?.message || 'An error ocured')
      setlaoder(false)
    }
  }
  return (
    <div className='container mx-auto my-20 px-5 md:px-0'>
      <div className='grid lg:grid-cols-2 grid-cols-1 sm:mx-10 gap-x-28  '>
        <div className='justify-center flex flex-col items-center'>
          <img src={Contact} className='object-contain' />
        </div>
        <div>
          <div className='text-sm sm:mt-10 font-Campton sm:block'>
            <div className='md:block hidden'>
              <p style={{ color: '#6B6B6C' }}>Like what you see? </p>
              <p style={{ color: '#6B6B6C' }}>Got a project you’d like to talk about?</p>
              <p style={{ color: '#6B6B6C' }}>A new brief to chew up?</p>
            </div>
            <h1
              className=' md:text-6xl text-xl font-campton-bold font-light mt-4  '
              style={{ color: '#4A4A4C' }}
            >
              {' '}
              Get in touch
            </h1>
          </div>
          <div className='font-Campton'>
            <form onSubmit={handleSubmit}>
              {INPUTS_FIELDS.map(input => (
                <div className='grid grid-cols-1  mt-7' key={input}>
                  <label
                    className='font-bold text-xs font-campton-light text-primary'
                    style={{ fontSize: '.75rem' }}
                  >
                    {' '}
                    {input.label}
                  </label>
                  <input
                    className='border-b-2 pb-1 text-lg font-thin outline-none focus:outline-none focus:border-blue-400 duration-200 font-campton-light mt-2'
                    placeholder={input.placeholder}
                    name={input.name}
                    type={input.type}
                    required={input.required}
                    onChange={handleInputChange}
                    style={{ color: '#6B6B6C' }}
                  />
                </div>
              ))}
              {/* <p>{error}</p> */}
              <button type='submit'>
                <div className='flex font-medium mt-5 text-xs'>
                  <h4 style={{ color: '#6B6B6C' }}>Lets Go </h4>
                  <div className='pt-2 pl-2 '>
                    {loader ? <i className='fa fa-circle-o-notch fa-spin' /> : <img src={arrow} />}{' '}
                  </div>
                </div>
              </button>
              <p>{error}</p>
              <p>{message}</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Form
