import React from 'react'
import EthIcon from '../../assets/images/EthIcon.png'
import vector from '../../assets/images/Vector.png'

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <div className='bg-img'>
      <div className='pt-14 pb-5 px-5 md:pl-32 pr-5'>
        <div className='grid gap-y-5 md:grid-cols-3 pb-4'>
          <div className='flex items-start'>
            <img className='mr-10 md:w-16 md:h-16' src={EthIcon} />
            <div className=' text-white font-campton-light'>
              <p className='text-sm'>18 Johnson Street, Off Coker Road,</p>
              <p className='text-sm'>Ilupeju, Lagos,</p>
              <p className='text-sm'> Nigeria.</p>
            </div>
          </div>
          <div className='md:flex flex-col items-center'>
            <div className='font-san text-white'>
              <p className='text-sm font-bold'>hello@ethglobalinc.com</p>
              <p className='text-sm font-bold'>ethgloballtd@gmail.com</p>
              <p className='text-sm mt-3'>+234 818 131 7544</p>
              <p className='text-sm'>+234 803 246 6355</p>
            </div>
          </div>
          <div>
            <div className='font-sans text-white'>
              <a
                href='https://web.facebook.com/ETH-Global-105924591935607'
                target='_blank'
                rel='noreferrer'
              >
                <p className='text-sm mt-2 font-semibold font-Campton'>Facebook</p>
              </a>
              <a href='https://www.instagram.com/ethglobalinc/' target='_blank' rel='noreferrer'>
                <p className='text-sm mt-2 font-Campton'>Instagram</p>
              </a>
              <a
                href='https://www.linkedin.com/company/%E2%80%99%C3%AAth-global/'
                target='_blank'
                rel='noreferrer'
              >
                <p className='text-sm mt-2 font-Campton'>Linkedin</p>
              </a>
            </div>
            <div className='flex justify-end sm:pr-20 md:pr-20'>
              <div className=' bg-primary-pink w-7 h-8'>
                <button onClick={scrollToTop}>
                  <img className='inline text-center px-2 ' src={vector} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='font-sans text-white pb-5 border-t-2 pl-20'>
          <p className=' mt-3  text-xs sm:mx-auto'>&copy; 2021 ’êth Global. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  )
}
export default Footer
