import React from 'react'
import { Swiper, Autoplay, Pagination, Navigation } from 'swiper'
import { SwiperSlide, Swiper as SwiperContainer } from 'swiper/react'

// import kk from '../../assets/images/image2.png'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

Swiper.use([Autoplay, Pagination, Navigation])

const Ourportfolio = () => {
  return (
    <div>
      <div className='flex justify-center'>
        <div className='mt-20 mb-5'>
          <h3 className=' text-2xl text-center' id='portfolio'>
            Our Portfolio
          </h3>
          <div className='w-14 h-1.5 mt-3 bg-primary-pink mx-auto' />
        </div>
      </div>
      <div className='container mx-auto px-20 relative pb-20'>
        <div className='px-30 mx-auto '>
          <SwiperContainer
            spaceBetween={5}
            slidesPerView={1}
            autoplay
            navigation={{
              nextEl: 'button#portfolio-next-slide-btn.portfolio-next-slide-btn',
              prevEl: 'button#portfolio-prev-slide-btn.portfolio-prev-slide-btn'
            }}
            pagination
            loop
            onSlideChange={() => console.log('slide change')}
            onSwiper={swiper => console.log(swiper)}
          >
            {[
              '1.png',
              '2.png',
              '3.png',
              '4.png',
              '5.png',
              '6.png',
              '7.png',
              '8.png',
              '9.png',
              '10.png',
              '11.png',
              '12.png',
              '13.png',
              '14.png',
              '15.png',
              '16.png',
              '17.png',
              '18.png',
              '19.png',
              '20.png',
              '21.png',
              '22.png',
              '23.png',
              '24.png',
              '25.png',
              '26.png',
              '27.png',
              '29.png',
              '30.png',
              '31.png',
              '32.png',
              '33.png',
              '34.png'
            ].map(image => (
              <SwiperSlide key={image}>
                <img
                  key={image}
                  src={`/portfolio/${image}`}
                  className='portflio-images w-full md:h-full object-cover py-4'
                />
              </SwiperSlide>
            ))}
          </SwiperContainer>
        </div>
        <div className='absolute top-0 flex flex-col justify-center h-full w-full right-0 left-0'>
          <div className='flex justify-between'>
            <button
              id='portfolio-prev-slide-btn'
              className='border-primary-pink border portfolio-prev-slide-btn rounded-full w-10 h-10 grid place-content-center z-50 outline-none text-primary-pink text-3xl'
            >
              <i className='fa fa-angle-left' />
            </button>
            <button
              id='portfolio-next-slide-btn'
              className='border-primary-pink border portfolio-next-slide-btn rounded-full w-10 h-10 grid place-content-center z-10 outline-none text-primary-pink text-3xl'
            >
              <i className='fa fa-angle-right' />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ourportfolio
