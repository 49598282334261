import React from 'react'
import { Swiper, Autoplay, Pagination, Navigation } from 'swiper'
import { SwiperSlide, Swiper as SwiperContainer } from 'swiper/react'
// import mm from '../../assets/images/Ellipse2.png'
// import nn from '../../assets/images/Ellipse3.png'
import quoteCircle from '../../assets/images/quote-circle.svg'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { testimonies } from '../../data/testimonies'

Swiper.use([Autoplay, Pagination, Navigation])

const Ourclients = () => {
  return (
    <div id='clients' className='clients mt-20 py-16' style={{ backgroundColor: '#F8F8F8' }}>
      <div className='flex justify-center mb-10'>
        <div className='text-center'>
          <p className='font-campton-light text-xl' style={{ color: '#201E1E' }}>
            What Our Clients are Saying
          </p>
          <div className='bg-primary-purple w-14 h-1.5 mt-3 mx-auto' />
        </div>
      </div>
      <div className='container mx-auto px-5'>
        <div className='hidden lg:block'>
          <SwiperContainer
            navigation={{
              nextEl: 'button#next-slide-btn.next-slide-btn',
              prevEl: 'button#prev-slide-btn.prev-slide-btn'
            }}
            autoplay
            centeredSlides
            loop
            slidesPerView={3}
            spaceBetween={60}
          >
            {testimonies.map(testimony => (
              <SwiperSlide key={testimony.email} className='pt-11'>
                <figure className='absolute top-6 left-5' style={{ zIndex: 50 }}>
                  <img className='w-12 h-12' src={quoteCircle} alt='' />
                </figure>
                <div
                  style={{ background: '#FFDDD8' }}
                  className='h-full lg:h-64 flex flex-col justify-between p-6 relative pt-8'
                >
                  <p className='text-sm font-campton-light' style={{ color: '#7B6759' }}>
                    {testimony.whatYouThink}
                  </p>
                  <div className='flex items-center mt-10'>
                    {testimony.image ? (
                      <img src={testimony.image} className='w-12 h-12' alt='first pic' />
                    ) : (
                      <div className='bg-gray-800 w-12 h-12 rounded-full grid place-content-center'>
                        <i className='fa text-white fa-user fa-2x' />
                      </div>
                    )}
                    <div className='ml-5'>
                      <h4 className='text-lg font-campton-light' style={{ color: '#7B6759' }}>
                        {testimony.name}
                      </h4>
                      <p className='text-xs font-campton-light' style={{ color: '#45362C' }}>
                        {testimony.positions}, {testimony.company}
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </SwiperContainer>
        </div>
        <div className='lg:hidden'>
          <SwiperContainer
            autoplay
            centeredSlides
            loop
            navigation={{
              nextEl: 'button#next-slide-btn.next-slide-btn',
              prevEl: 'button#prev-slide-btn.prev-slide-btn'
            }}
            slidesPerView={1}
            slideActiveClass='active'
            spaceBetween={20}
          >
            {testimonies.map(testimony => (
              <SwiperSlide key={testimony.email} className='pt-11'>
                <figure className='absolute top-6 left-5' style={{ zIndex: 50 }}>
                  <img className='w-12 h-12' src={quoteCircle} alt='' />
                </figure>
                <div
                  style={{ background: '#FFDDD8' }}
                  className='h-full lg:h-64 flex flex-col justify-between p-6 relative'
                >
                  <p className='text-xs font-campton-light' style={{ color: '#7B6759' }}>
                    {testimony.whatYouThink}
                  </p>
                  <div className='flex items-center mt-10'>
                    {testimony.image ? (
                      <img src={testimony.image} className='w-12 h-12' alt='first pic' />
                    ) : (
                      <div className='bg-gray-800 w-12 h-12 rounded-full grid place-content-center'>
                        <i className='fa text-white fa-user fa-2x' />
                      </div>
                    )}
                    <div className='ml-5'>
                      <h4 className='text-lg font-campton-light' style={{ color: '#7B6759' }}>
                        {testimony.name}
                      </h4>
                      <p className='text-xs font-campton-light' style={{ color: '#45362C' }}>
                        {testimony.positions}, {testimony.company}
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </SwiperContainer>
        </div>
        <div
          style={{ maxWidth: '15rem' }}
          className='mt-10 mx-auto flex flex-col justify-center h-full w-full inset-0'
        >
          <div className='px-2 sm:px-10'>
            <div className='flex justify-between'>
              <button
                id='prev-slide-btn'
                className='border-primary-pink border prev-slide-btn rounded-full w-10 h-10 grid place-content-center z-10 outline-none text-primary-pink text-3xl'
              >
                <i className='fa fa-angle-left' />
              </button>
              <button
                id='next-slide-btn'
                className='border-primary-pink border next-slide-btn rounded-full w-10 h-10 grid place-content-center z-10 outline-none text-primary-pink text-3xl'
              >
                <i className='fa fa-angle-right' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ourclients
