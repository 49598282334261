import React from 'react'
import rr from '../../assets/images/logo.png'

const Navbar = () => {
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    })
  }

  return (
    <div className='absolute z-10 top-0 right-0 left-0'>
      <div className='flex justify-between py-5 px-5 md:px-16'>
        <img src={rr} className='object-contain  pt-5' alt='eth Global' />{' '}
        <button
          onClick={scrollToBottom}
          style={{
            top: '30px',
            right: '40px'
          }}
          className=' border-2 border-primary-pink bg-white fixed  text-primary-pink   font-semibold py-2 px-7'
        >
          Get Quote
        </button>
      </div>
    </div>
  )
}
export default Navbar
